/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import InquiryToCustomer from "../../../types/Entities/InquiryToCustomer";
import { Grid } from "@mui/material";
import DownloadTile from "../../PetitionSummary/Documents/DownloadTile";

type InquiryToCustomerMediaObjectsProps = {
  inquiryToCustomer: InquiryToCustomer;
};

export default function InquiryToCustomerMediaObjects({ inquiryToCustomer }: InquiryToCustomerMediaObjectsProps) {
  return (
    <>
      {inquiryToCustomer.mediaObjects?.length > 0 && (
        <Grid container justifyContent={"flex-start"} spacing={3}>
          {inquiryToCustomer.mediaObjects.map((mediaObject) => (
            <Grid item key={mediaObject.id}>
              <DownloadTile mediaObject={mediaObject} size={"minimal"} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
